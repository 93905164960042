import {
  getUser,
  getColumnName,
  refreshToken,
  isLoggedIn,
} from "../services/auth"
import * as React from "react"
import { useState, useEffect } from "react"
import Form from "react-bootstrap/Form"
import { Col, Button, Container } from "react-bootstrap"
export default function HunterForm(props) {
  const [actiontype, setActiontype] = useState()
  const hunterlist = ["渔网", "鱼叉"]
  const alphabet = "ABCDEFGHIJKLMNOPQRSTUVWXYZ"
  const { maptable, huntdata } = props
  const [atime, setAtime] = useState(0)
  const [ttime, setTtime] = useState(0)
  const [apoint, setApoint] = useState(0)
  const [tpoint, setTpoint] = useState(0)
  const [aused, setAused] = useState(false)
  const [tused, setTused] = useState(false)
  const [animalcount, setAnimalCount] = useState(0)
  const [locx, setLocx] = useState()
  const [locy, setLocy] = useState()
  const BASE_URL = "47.99.220.122:8000"
  useEffect(() => {
    refreshToken()
    if (!isLoggedIn()) return
    ;(async () => {
      if (huntdata && huntdata.length > 0) {
        const arc_item = huntdata[0]
        const trap_item = huntdata[1]
        const animal_count = huntdata[2]
        setAtime(arc_item.time)
        setTtime(trap_item.time)
        setApoint(arc_item.point)
        setTpoint(trap_item.point)
        setAused(arc_item.used)
        setTused(trap_item.used)
        setAnimalCount(animal_count.animal)
      }
    })()
  }, [huntdata, setAtime, setTtime, setApoint, setTpoint, setAused, setTused])

  let location_x, location_y
  if (!maptable) {
    location_x = []
    location_y = []
  } else {
    console.log("props is here !!!!!", maptable)
    const x_set = [...Array(maptable.high_x + 1).keys()]
    const y_set = [...Array(maptable.high_y + 1).keys()]
    location_x = x_set.slice(maptable.low_x)
    location_y = y_set.slice(maptable.low_y)
  }

  const handleSubmit = event => {
    event.preventDefault()
    const accessToken = getUser().access
    ;(async () => {
      const pollsurl = BASE_URL + "/login/updateHunt"
      const method = "put"
      const headers = {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      }
      const body = JSON.stringify({
        item: actiontype,
        x: locx,
        y: locy,
      })
      const response = await fetch(pollsurl, { method, headers, body })
      const response_data = await response.json()
      if (response.ok) {
        alert(response_data.message)
        window.location.reload(false)
      } else {
        alert(response_data.message)
      }
    })()
  }

  const handleChange = event => {
    if (event.target.id == "form_location_x") {
      setLocx(event.target.value)
    }
    if (event.target.id == "form_location_y") {
      setLocy(event.target.value)
    }
    if (event.target.id == "type") {
      setActiontype(event.target.value)
    }
  }
  console.log(huntdata)
  console.log("tpoint issssssssssss", tpoint)
  return (
    <Form onSubmit={handleSubmit}>
      <Form.Row style={{ padding: "50px" }}>
        <Container style={{ width: "25%", minWidth: "50px" }}>
          <img src="../images/net.png"></img>
          <p>当前时段渔网 {tused ? "已被使用" : "未被使用"}</p>
          <p>当前时段渔网参与人数：{tpoint}</p>
        </Container>
        <Container style={{ width: "25%", minWidth: "50px" }}>
          <img src="../images/fork.png"></img>
          <p>当前时段鱼叉 {aused ? "已被使用" : "未被使用"}</p>
        </Container>
        <Container style={{ width: "25%", minWidth: "50px" }}>
          <img src="../images/fish.png"></img>
          <p>今日已捕捉鱼数量： {animalcount}</p>
        </Container>
      </Form.Row>
      <Form.Row>
        <Form.Group
          as={Col}
          controlId="form_location_x"
          onChange={handleChange}
        >
          <Form.Label>行数</Form.Label>
          <Form.Control
            as="select"
            defaultValue="请选择"
            onChange={handleChange}
          >
            <option key={1}>请选择</option>
            {location_x.map((data, index) => (
              <option key={index + 1}>{getColumnName(data - 1)}</option>
            ))}
          </Form.Control>
        </Form.Group>
        <Form.Group as={Col} controlId="form_location_y">
          <Form.Label>列数</Form.Label>
          <Form.Control
            as="select"
            defaultValue="请选择"
            onChange={handleChange}
          >
            <option key={1}>请选择</option>
            {location_y.map((data, index) => (
              <option key={index + 1}>{data}</option>
            ))}
          </Form.Control>
        </Form.Group>
        <Form.Group as={Col} controlId="type">
          <Form.Label>类别</Form.Label>
          <Form.Control
            as="select"
            defaultValue="请选择"
            onChange={handleChange}
          >
            <option key={1}>请选择</option>
            {hunterlist.map((data, index) => (
              <option key={index + 1}>{data}</option>
            ))}
          </Form.Control>
        </Form.Group>
      </Form.Row>
      <Form.Row style={{ justifyContent: "center" }}>
        <Button type="submit">提交</Button>
      </Form.Row>
    </Form>
  )
}

import {
  getUser,
  getColumnName,
  refreshToken,
  isLoggedIn,
} from "../services/auth"
import * as React from "react"
import { useState, useEffect } from "react"
import Form from "react-bootstrap/Form"
import { Col, Button, Container } from "react-bootstrap"
const BASE_URL = "47.99.220.122:8000"
export default function CollectForm(props) {
  const [nfood, setNFood] = useState(0)
  const [wood, setWood] = useState(0)
  const [rfood, setRFood] = useState(0)
  const [rawwater, setWater] = useState(0)
  const [srfood, setSRFood] = useState(0)
  const [ssrfood, setSSRFood] = useState(0)

  const [pnfood, setPNFood] = useState(0)
  const [pwood, setPWood] = useState(0)
  const [prfood, setPRFood] = useState(0)
  const [prawwater, setPWater] = useState(0)
  const [psrfood, setPSRFood] = useState(0)
  const [pssrfood, setPSSRFood] = useState(0)
  const [locx, setLocx] = useState()
  const [locy, setLocy] = useState()

  const [actiontype, setActiontype] = useState()

  const actionlist = ["食物", "木材", "原水"]
  const alphabet = "ABCDEFGHIJKLMNOPQRSTUVWXYZ"
  const { todayrecord, singlerecord, maptable } = props

  let location_x, location_y
  if (!maptable) {
    location_x = []
    location_y = []
  } else {
    console.log("props is here !!!!!", maptable)
    const x_set = [...Array(maptable.high_x + 1).keys()]
    const y_set = [...Array(maptable.high_y + 1).keys()]
    location_x = x_set.slice(maptable.low_x)
    location_y = y_set.slice(maptable.low_y)
  }

  const handleSubmit = event => {
    event.preventDefault()
    const accessToken = getUser().access
    ;(async () => {
      const pollsurl = BASE_URL + "/login/updateCollect"
      const method = "put"
      const headers = {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      }
      const body = JSON.stringify({
        item: actiontype,
        x: locx,
        y: locy,
      })
      const response = await fetch(pollsurl, { method, headers, body })
      const response_data = await response.json()
      if (response.ok) {
        alert(response_data.message)
        window.location.reload(false)
      } else {
        alert(response_data.message)
      }
    })()
  }

  useEffect(() => {
    refreshToken()
    if (!isLoggedIn()) return
    ;(async () => {
      if (todayrecord) {
        setNFood(todayrecord.nfood)
        setRFood(todayrecord.rfood)
        setSRFood(todayrecord.srfood)
        setSSRFood(todayrecord.ssrfood)
        setWater(todayrecord.raw_water)
        setWood(todayrecord.wood)
      }
      if (singlerecord) {
        setPNFood(singlerecord.nfood)
        setPRFood(singlerecord.rfood)
        setPSRFood(singlerecord.srfood)
        setPSSRFood(singlerecord.ssrfood)
        setPWater(singlerecord.raw_water)
        setPWood(singlerecord.wood)
      }
    })()
  }, [
    todayrecord,
    singlerecord,
    setNFood,
    setRFood,
    setSRFood,
    setSSRFood,
    setWood,
    setPNFood,
    setPRFood,
    setPSRFood,
    setPSSRFood,
    setPWood,
  ])

  const handleChange = event => {
    if (event.target.id == "form_location_x") {
      setLocx(event.target.value)
    }
    if (event.target.id == "form_location_y") {
      setLocy(event.target.value)
    }
    if (event.target.id == "type") {
      setActiontype(event.target.value)
    }
  }

  return (
    <Form onSubmit={handleSubmit} style={{ padding: "50px" }}>
      <Form.Row>
        <Container
          style={{
            width: "20%",
            minWidth: "50px",
          }}
        >
          <img src="../images/jiegu.png" />
          <p>今日部落总采集量：{nfood}</p>
          <p>今日个人采集量：{pnfood}</p>
        </Container>
        <Container style={{ width: "20%", minWidth: "50px" }}>
          <img src="../images/lanmei.png"></img>
          <p>今日部落总采集量：{rfood}</p>
          <p>今日个人采集量：{prfood}</p>
        </Container>
        <Container style={{ width: "20%", minWidth: "50px" }}>
          <img src="../images/fupengzi.png"></img>
          <p>今日部落总采集量：{srfood}</p>
          <p>今日个人采集量：{psrfood}</p>
        </Container>
        <Container style={{ width: "20%", minWidth: "50px" }}>
          <img src="../images/yunmei.png"></img>
          <p>今日部落总采集量：{ssrfood}</p>
          <p>今日个人采集量：{pssrfood}</p>
        </Container>

        <Container style={{ width: "20%", minWidth: "50px" }}>
          <img src="../images/water.png"></img>
          <p>今日部落总采集量：{rawwater}</p>
          <p>今日个人采集量：{prawwater}</p>
        </Container>
        <Container style={{ width: "20%", minWidth: "50px" }}>
          <img src="../images/wood.png"></img>
          <p>今日部落总采集量：{wood}</p>
          <p>今日个人采集量：{pwood}</p>
        </Container>
      </Form.Row>
      <Form.Row>
        <Form.Group
          as={Col}
          controlId="form_location_x"
          onChange={handleChange}
        >
          <Form.Label>行数</Form.Label>
          <Form.Control
            as="select"
            defaultValue="请选择"
            onChange={handleChange}
          >
            <option key={1}>请选择</option>
            {location_x.map((data, index) => (
              <option key={index + 1}>{getColumnName(data - 1)}</option>
            ))}
          </Form.Control>
        </Form.Group>
        <Form.Group as={Col} controlId="form_location_y">
          <Form.Label>列数</Form.Label>
          <Form.Control
            as="select"
            defaultValue="请选择"
            onChange={handleChange}
          >
            <option key={1}>请选择</option>
            {location_y.map((data, index) => (
              <option key={index + 1}>{data}</option>
            ))}
          </Form.Control>
        </Form.Group>

        <Form.Group as={Col} controlId="type">
          <Form.Label>类别</Form.Label>
          <Form.Control
            as="select"
            defaultValue="请选择"
            onChange={handleChange}
          >
            <option key={1}>请选择</option>
            {actionlist.map((data, index) => (
              <option key={index + 1}>{data}</option>
            ))}
          </Form.Control>
        </Form.Group>
      </Form.Row>
      <Form.Row style={{ justifyContent: "center" }}>
        <Button type="submit">提交</Button>
      </Form.Row>
    </Form>
  )
}

// CollectForm.propTypes = {
//   maptable: PropTypes.arrayOf(
//     PropTypes.oneOfType([PropTypes.string, PropTypes.number])
//   ),
// }

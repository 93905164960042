import * as React from "react"
import { useState, useEffect } from "react"
import NavBar from "../components/navbar"
import HunterForm from "../components/hunterform"
// import TodayTable from "../components/todaytable"
import "../components/login.css"
import {
  // handleMapTable,
  handleMap,
  handleTodayRecord,
  handleEnergy,
  handleHuntData,
} from "../services/api"
import { isLoggedIn, refreshToken, getColumnName } from "../services/auth"

import { Tabs, Tab, Tabpane } from "react-bootstrap"
import Form from "react-bootstrap/Form"
import CollectForm from "../components/collectform"

export default function Collect() {
  const [maptable, setMaptable] = useState(null)
  const [todayrecord, setTodayrecord] = useState(null)
  const [singlerecord, setSinglerecord] = useState(null)
  // const [map, setMap] = useState([])
  // const [datatable, setDatatable] = useState([])
  const [tribename, setTribename] = useState("")
  const [mapstring, setMapstring] = useState(null)
  const [energypoint, setEnergyPoint] = useState(0)
  const [huntdata, setHuntdata] = useState([])

  useEffect(() => {
    refreshToken()
    if (!isLoggedIn()) return
    ;(async () => {
      const [
        trdata,
        mapdata,
        // tabledata,
        energydata,
        hdata,
      ] = await Promise.all([
        handleTodayRecord(),
        handleMap(),
        // handleMapTable(),
        handleEnergy(),
        handleHuntData(),
      ])
      // setPointType(tabledata.point_info.type)
      // setMaptable(tabledata)
      setTodayrecord(trdata[0])
      setSinglerecord(trdata[1])
      setMaptable(mapdata)
      setMapstring(mapdata.current_map)
      // setDatatable(tabledata.point_info)
      setTribename(trdata.tribe_name)
      setEnergyPoint(energydata)
      setHuntdata(hdata)
    })()
  }, [
    // setMaptable,
    setTodayrecord,
    setMaptable,
    // setDatatable,
    setTribename,
    setEnergyPoint,
    setTribename,
    setSinglerecord,
  ])

  console.log("map is", maptable)
  console.log("todayrecord is", todayrecord)
  console.log("singlerecord is", singlerecord)

  return (
    <span>
      <NavBar />
      <span className="historycenter">
        <br />
        <h2>今日{tribename}采集总量</h2>
        <p styles={{ "text-align": "right" }}>当前剩余行动点：{energypoint}</p>
        <Tabs defaultActiveKey="map" id="uncontrolled-tab-example">
          <Tab
            eventKey="map"
            title="地图"
            style={
              ({ color: "black" }, { backgroundColor: "#ff8a65", opacity: 0.8 })
            }
          >
            <span className="center">
              <br />
              <span>
                <img
                  src="holder.js/1000x600"
                  src={`data:image/jpeg;base64,${mapstring}`}
                  width={600}
                  // formats={["AUTO", "WEBP", "AVIF"]}
                  alt="A Gatsby astronaut"
                />
              </span>
            </span>
          </Tab>
          {/* <Tab eventKey="table" title="采集点信息">
              <TodayTable type={pointtype} datatable={datatable} />
            </Tab> */}
          <Tab eventKey="collect" title="采集">
            <CollectForm
              todayrecord={todayrecord}
              singlerecord={singlerecord}
              maptable={maptable}
            />
          </Tab>
          <Tab eventKey="hunting" title="捕捞">
            <HunterForm maptable={maptable} huntdata={huntdata} />
          </Tab>
        </Tabs>
      </span>
    </span>
  )
}
